export const getErrorMessages = (err: any) => {
    let message = ''
    if (err.details && err.details.code) {
        // not sure why this is code, and not just details
        return [err.details.code]
    } else if (err.detail) {
        return [err.detail]
    } else if (typeof (Object.keys(err).length > 0 && err[Object.keys(err)[0]][0]) === 'object') {
        // DRF error format extended with "code" to help building more complicated error UIs:
        // {"some_field": [{"detail": "some error message", "code": "some_error_code"}]}
        type Error = {
            [key: string]: [
                {
                    detail: string
                },
            ]
        }
        message = Object.entries(err as Error).reduce((acc, [, val]) => {
            return (
                acc +
                val.reduce((acc1, val2) => {
                    return acc1 + val2.detail + '\n'
                }, '')
            )
        }, '')
    } else {
        // Default DRF error format:
        // {"some_field": ["some error message", "some other error message"}]}
        type Error = {
            [key: string]: string[]
        }
        message = Object.entries(err as Error).reduce((acc, [, val]) => {
            return acc + val.join('\n') + '\n'
        }, '')
    }
    return message.split('\n').slice(0, -1)
}

export const getErrorFields = (err: any) => {
    if (err.detail || err.details) {
        return ['non-field error']
    } else {
        return Object.keys(err)
    }
}

export const getCookieValue = (cookieName: string, cookies?: string | undefined): string | undefined => {
    if (cookies === undefined) {
        return undefined
    }
    const cookiesList = cookies.split(';')
    for (let i = 0; i < cookiesList.length; i++) {
        let cookie = cookiesList[i].trim()
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1)
        }
    }
    return undefined
}
