import { giphyUrl } from '@/app/util/env'
import { giphyDarkestGrey } from '@giphy/colors'
import { setGADataLayer } from 'analytics'
import { useContext, useReducer, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import styled from 'styled-components'
import Checkbox from 'ui/src/components/controls/checkbox'
import { getErrorMessages } from 'utils/src/api'
import { timeoutFetch } from 'utils/src/api/fetch'
import { FlashMessageContext } from '../../context/flash-message'
import { appleLogo, fbLogo } from '../../util/asset-url'
import EmailValidation from './email-validation'
import AuthModal from './modal'
import PasswordVisibility from './password-visibility'
import { Button, Form, SocButton, SocialButtons, Terms } from './sc'

const SignUpRecovery = styled.p`
    font-size: 14px;
    text-align: center;
`
const SignUpRecoveryLink = styled.a`
    font-weight: bold;
    cursor: pointer;
`

const TermsCheckbox = styled(Checkbox)`
    display: inline-block;
    margin-right: 6px;
    vertical-align: top;
`

const ReCAPTCHAContainer = styled.div`
    min-height: 74px;
    width: 325px;
    display: flex;
    justify-content: center;
`

const JoinForm = () => {
    const form = useRef<HTMLFormElement>(null)
    const emailInput = useRef<HTMLInputElement>(null)
    const userInput = useRef<HTMLInputElement>(null)
    const passwordInput = useRef<HTMLInputElement>(null)
    const [viewPassword, setViewPassword] = useState(false)
    const passwordInput2 = useRef<HTMLInputElement>(null)
    const [viewPassword2, setViewPassword2] = useState(false)
    const captcha = useRef<ReCAPTCHA>(null)
    const passwordsMatch = passwordInput.current?.value === passwordInput2.current?.value
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [terms, setTerms] = useState(false)
    const [showRecoveryModal, setShowRecoveryModal] = useState(false)
    const [emailValidation, setEmailValidation] = useState({
        show: false,
        email: '',
    })
    const [loading, setLoading] = useState(false)
    const { showMessage } = useContext(FlashMessageContext)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setLoginMethod] = useLocalStorage('login_type')
    const submit = async () => {
        const formData = new FormData(form.current!)
        formData.append('platform', 'web')
        formData.append('recaptcha_token', captcha.current!.getValue()!)
        try {
            setLoading(true)
            const response = await timeoutFetch({
                url: '/api/v3/register',
                options: {
                    method: 'POST',
                    body: formData,
                },
            })
            if (response.ok) {
                setLoginMethod('email')
                setGADataLayer({
                    event: 'signup_form_success',
                    options: {
                        form: {
                            formName: 'signup',
                        },
                    },
                })
                setEmailValidation({
                    show: true,
                    email: emailInput.current!.value,
                })
            } else {
                const errorMessages = getErrorMessages(await response.json())
                if (errorMessages.length > 1) {
                    showMessage({
                        message: (
                            <>
                                <div>Please try again after fixing the following errors:</div>
                                {errorMessages.map((mes, index) => {
                                    return (
                                        <div key={index}>
                                            {index + 1}. {mes}
                                        </div>
                                    )
                                })}
                            </>
                        ),
                        type: 'error',
                    })
                } else {
                    showMessage({
                        message: errorMessages.map((mes, index) => <div key={index}>{mes}</div>),
                        type: 'error',
                    })
                }
                captcha.current?.reset()
                setLoading(false)
                // Send GTM error
                setGADataLayer({
                    event: 'signup_form_error',
                    options: {
                        form: {
                            formName: 'signup',
                            formError: errorMessages.join(','),
                        },
                    },
                })
            }
        } catch (err: any) {}
    }
    return (
        <>
            <SignUpRecovery>
                Already started creating an account?{' '}
                <SignUpRecoveryLink onClick={() => setShowRecoveryModal(true)} type="button">
                    Finish Sign Up
                </SignUpRecoveryLink>
            </SignUpRecovery>
            <Form ref={form} noValidate>
                <div>
                    <input
                        autoComplete="username"
                        placeholder="Email Address"
                        name="email"
                        required
                        type="email"
                        ref={emailInput}
                        onKeyUp={forceUpdate}
                        onInput={forceUpdate}
                    />
                    <div className="error-message">{emailInput.current?.validationMessage}</div>
                </div>
                <div>
                    <input
                        placeholder="Username"
                        name="username"
                        required
                        type="text"
                        autoComplete="off"
                        ref={userInput}
                        onKeyUp={forceUpdate}
                        onInput={forceUpdate}
                    />
                    <div className="error-message">{userInput.current?.validationMessage}</div>
                </div>
                <div style={{ position: 'relative' }}>
                    <input
                        autoComplete="new-password"
                        name="password"
                        placeholder="Password"
                        required
                        type={viewPassword ? 'text' : 'password'}
                        minLength={8}
                        onInput={forceUpdate}
                        onKeyUp={forceUpdate}
                        ref={passwordInput}
                    />
                    <PasswordVisibility viewPassword={viewPassword} setViewPassword={setViewPassword} />
                    <div className="error-message">{passwordInput.current?.validationMessage}</div>
                </div>
                <div style={{ position: 'relative' }}>
                    <input
                        autoComplete="new-password"
                        name="password"
                        placeholder="Confirm your password"
                        required
                        type={viewPassword2 ? 'text' : 'password'}
                        minLength={8}
                        onKeyUp={forceUpdate}
                        onInput={forceUpdate}
                        ref={passwordInput2}
                    />
                    <PasswordVisibility viewPassword={viewPassword2} setViewPassword={setViewPassword2} />
                    <div className="error-message">{passwordInput.current?.validationMessage}</div>
                </div>
                <ReCAPTCHAContainer>
                    <ReCAPTCHA
                        sitekey="6LdLAasUAAAAAJIGrMFJ9wvf1UXIibLjHyeCZgH3"
                        ref={captcha}
                        onInput={forceUpdate}
                        onChange={forceUpdate}
                    />
                </ReCAPTCHAContainer>
                <Terms>
                    <TermsCheckbox
                        checked={terms}
                        name="Terms"
                        size="small"
                        title="Terms of Service"
                        onChange={(isChecked: boolean) => {
                            setTerms(isChecked)
                        }}
                    />
                    I agree to the <a href={`/terms`}>Terms of Service</a> and{' '}
                    <a href="https://support.giphy.com/hc/en-us/articles/360032872931">Privacy Policy</a>
                </Terms>
                <Button
                    size="medium"
                    gradient="purple-indigo"
                    loading={loading}
                    disabled={
                        !form.current?.checkValidity() || !passwordsMatch || !terms || !captcha.current?.getValue()
                    }
                    onClick={submit}
                >
                    Sign Up
                </Button>
            </Form>
            <SocialButtons>
                <Button size="medium" href={`${giphyUrl}/login/facebook/`} color={giphyDarkestGrey}>
                    <SocButton>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img src={fbLogo} alt="Facebook" style={{ margin: -6 }} />
                        Sign up with Facebook
                    </SocButton>
                </Button>
                <Button size="medium" href={`${giphyUrl}/login/apple/`} color={giphyDarkestGrey}>
                    <SocButton>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img src={appleLogo} alt="apple login" height="20" />
                        Sign up with Apple
                    </SocButton>
                </Button>
            </SocialButtons>
            {showRecoveryModal &&
                createPortal(
                    <AuthModal
                        onClose={(email?: string) => {
                            setShowRecoveryModal(false)
                            if (email) {
                                setEmailValidation({ show: true, email })
                            }
                        }}
                    />,
                    document.body,
                    'recovery-modal'
                )}
            {emailValidation.show &&
                createPortal(<EmailValidation email={emailValidation.email} />, document.body, 'email-validation')}
        </>
    )
}
export default JoinForm
