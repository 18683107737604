import styled from 'styled-components'

const MessageLink = styled.a`
    display: block;
    color: #fff;
    text-decoration: underline;
`
const Title = styled.span`
    font-weight: bold;
`
const Emoji = styled.span`
    font-size: 30px;
`
const Message = styled.span`
    font-weight: normal;
`
export const AuthMessage = ({
    icon,
    title,
    message,
    linkURL,
    linkText,
}: {
    icon: string
    title: string
    message: string
    linkURL?: string
    linkText?: string
}) => {
    return (
        <>
            <Emoji>{icon}</Emoji>
            <div>
                <Title>{title}&nbsp;</Title>
                <Message>{message}</Message>
            </div>
            {linkURL && linkText && <MessageLink href={linkURL}>{linkText}</MessageLink>}
        </>
    )
}

export const Messages = {
    legacy_email_confirmation_success: {
        icon: '😎',
        title: 'Success!',
        message: 'Your GIPHY account is now validated.',
        linkText: 'Home',
        linkURL: '/',
        type: 'success',
    },
    email_validation_email_change_success: {
        icon: '😎',
        title: 'Success!',
        message: 'Your GIPHY email has been updated.',
        linkText: 'Home',
        linkURL: '/',
        type: 'success',
    },
    registration_confirm_success: {
        icon: '😎',
        title: 'Success!',
        message: 'Your email is now validated.',
        linkText: 'Login Now',
        linkURL: '/login',
        type: 'success',
    },
    registration_confirm_already_confirmed: {
        icon: '✌️',
        title: 'Confirmed!',
        message: 'Looks like your account was already validated!',
        linkText: 'Login Now',
        linkURL: '/login',
        type: 'success',
    },
    registration_confirm_failure_invalid_token: {
        icon: '☠️',
        title: 'Busted!',
        message: 'Your confirmation link is expired or invalid.',
        type: 'error',
    },
    registration_confirm_failure_already_logged_in: {
        icon: '🧐',
        title: 'You are already signed in!',
        message: 'Sign out first, then try again.',
        linkText: 'Sign Out',
        linkURL: '/logout',
        type: 'error',
    },
    registration_confirm_failure_unknown_error: {
        icon: '😭',
        title: 'Oops!',
        message: 'An unknown error occurred. Please try again.',
        type: 'error',
    },
    registration_send_email_error: {
        icon: '🤬',
        title: 'Error!',
        message: 'There was a problem sending your confirmation email. Please contact GIPHY Support.',
        linkText: 'Contact Support',
        linkURL: 'https://support.giphy.com',
        type: 'error',
    },
} as const
