import { CSRF_COOKIE_NAME, CSRF_HEADER_NAME } from '@/util/user'
import { giphyDarkestGrey } from '@giphy/colors'
import { setGADataLayer, setUser } from 'analytics'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { ILoggedInUser } from 'types'
import { getErrorMessages } from 'utils/src/api'
import { timeoutFetch } from 'utils/src/api/fetch'
import { FlashMessageContext } from '../../context/flash-message'
import { appleLogo, fbLogo } from '../../util/asset-url'
import { giphyUrl, giphyLocalUrl } from '@/app/util/env'
import { getCookie } from '../../util/url'
import PasswordVisibility from './password-visibility'
import { Button, ForgotPassword, Form, SocButton, SocialButtons, Terms } from './sc'

const LoginForm = ({ next }: { next?: string }) => {
    const form = useRef<HTMLFormElement>(null)
    const [loading, setLoading] = useState(false)
    const emailInput = useRef<HTMLInputElement>(null)
    const passwordInput = useRef<HTMLInputElement>(null)
    const [viewPassword, setViewPassword] = useState(false)
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [csrftoken, setCSRFToken] = useState('')
    const { showMessage } = useContext(FlashMessageContext)
    useEffect(() => {
        setCSRFToken(getCookie(CSRF_COOKIE_NAME))
    }, [])
    const login = async () => {
        setLoading(true)
        const loginData = new FormData(form.current!)
        if (next) {
            loginData.append('next', next)
        }
        try {
            const result = await timeoutFetch({
                url: `${giphyLocalUrl}/api/v1/users/login/`,
                options: {
                    method: 'POST',
                    body: loginData,
                    headers: {
                        [CSRF_HEADER_NAME]: csrftoken,
                    },
                },
                timeout: 10000,
            })
            const user: ILoggedInUser & { redirect_url: string } = await result.json()
            if (result.ok) {
                const gaUserData = {
                    loggedInStatus: true,
                    userType: user.is_staff ? 'staff' : user.user_type,
                    verifiedStatus: user.is_verified,
                    userID: `${user.id}`,
                    loginMethod: 'email',
                } satisfies Parameters<typeof setUser>[0]
                setUser(gaUserData)
                setGADataLayer({
                    event: 'login_form_success',
                    options: {
                        user: gaUserData,
                        form: {
                            formName: 'login',
                        },
                    },
                })
                let redirect = user.redirect_url || '/'
                // don't use router.push because it could be legacy
                location.href = redirect
            } else {
                showMessage({
                    message: getErrorMessages(user),
                    type: 'error',
                })
                setGADataLayer({
                    event: 'login_form_error',
                    options: {
                        form: {
                            formName: 'login',
                            formError: 'email,password',
                        },
                    },
                })
            }
        } catch (error) {
            console.error('Unspecified error', error)
        }
        setLoading(false)
    }
    return (
        <>
            <Form
                ref={form}
                onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (form.current?.checkValidity()) {
                        login()
                    }
                }}
            >
                <div>
                    <input
                        autoComplete="username"
                        placeholder="Email Address"
                        name="email"
                        required
                        type="email"
                        ref={emailInput}
                        onKeyUp={forceUpdate}
                        onInput={forceUpdate}
                    />
                    <div className="error-message">{emailInput.current?.validationMessage}</div>
                </div>
                <div style={{ position: 'relative' }}>
                    <input
                        name="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                        type={viewPassword ? 'text' : 'password'}
                        minLength={6}
                        onKeyUp={forceUpdate}
                        onInput={forceUpdate}
                        ref={passwordInput}
                    />
                    <PasswordVisibility viewPassword={viewPassword} setViewPassword={setViewPassword} />
                    <div className="error-message">{passwordInput.current?.validationMessage}</div>
                </div>
                <Button
                    size="medium"
                    gradient="purple-indigo"
                    loading={loading}
                    disabled={!form.current?.checkValidity()}
                    onClick={login}
                    type="submit"
                >
                    Log In
                </Button>
            </Form>
            <ForgotPassword href="/password/reset">Forgot Your Password?</ForgotPassword>
            <SocialButtons>
                <Button size="medium" color={giphyDarkestGrey} href={`${giphyUrl}/login/facebook/`}>
                    <SocButton>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img src={fbLogo} alt="Facebook" style={{ margin: -6 }} />
                        Log in with Facebook
                    </SocButton>
                </Button>
                <Button size="medium" color={giphyDarkestGrey} href={`${giphyUrl}/login/apple/`}>
                    <SocButton>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img src={appleLogo} alt="apple login" height="20" />
                        Log in with Apple
                    </SocButton>
                </Button>
            </SocialButtons>
            <Terms>
                By logging in you agree to GIPHY&apos;s <a href={`/terms`}>Terms of Service</a> and{' '}
                <a href="https://support.giphy.com/hc/en-us/articles/360032872931">Privacy Policy</a>
            </Terms>
        </>
    )
}
export default LoginForm
