import { VisibilityOffIcon, VisibilityOnIcon } from 'ui/src/icons/utility'
import { PasswordVisibilityClassname } from './sc'
import styled from 'styled-components'

const iconProps = {
    width: 20,
    height: 20,
    style: { display: 'inline' },
}
type Props = {
    viewPassword: boolean
    setViewPassword: (viewPassword: boolean) => void
}

const Container = styled.i``

const PasswordVisibility = ({ setViewPassword, viewPassword }: Props) => {
    return (
        <Container
            className={PasswordVisibilityClassname}
            onClick={() => setViewPassword(!viewPassword)}
            aria-label="Show password as plain text.
Warning: this will display your password on the screen."
        >
            {viewPassword ? <VisibilityOnIcon {...iconProps} /> : <VisibilityOffIcon {...iconProps} />}
        </Container>
    )
}

export default PasswordVisibility
