import VerifiedIcon from '@/app/icons/verified'
import { cn } from '@/util/cn'
import { IUser } from '@giphy/js-types'
import SmartLink from './smart-link'

type Props = { user: IUser; className?: string; alternateName?: string; useLink?: boolean }
const Username = ({ user, className, alternateName = '', useLink }: Props) => (
    <SmartLink
        noLink={!useLink}
        href={`/${user.username}`}
        className={cn(
            'text-giphyWhiteSmoke flex min-w-0 cursor-pointer items-center gap-1 text-sm font-bold',
            className
        )}
    >
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap subpixel-antialiased">
            {alternateName || `@${user.username}`}
        </div>
        {user.is_verified && <VerifiedIcon className={'fill-giphyBlue size-3.5 shrink-0'} />}
    </SmartLink>
)

export default Username
