'use client'
import { AuthMessage, Messages } from '@/components/auth/auth-message'
import Background from '@/components/auth/background'
import JoinForm from '@/components/auth/join'
import LoginForm from '@/components/auth/login'
import { FlashMessageContext } from '@/context/flash-message'
import { giphyUrl } from '@/app/util/env'
import { appendParams } from '@/util/url'
import Link from 'next/link'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import SegmentedControl_ from 'ui/src/components/controls/segmented-control'
import { desktop } from 'ui/src/css'
import { CloseIcon as _CloseIcon } from 'ui/src/icons/navigation'

const OuterContainer = styled.div`
    display: flex;
    height: 100vh;
    ${desktop(css`
        width: 50vw;
    `)}
`
const Container = styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 325px;
    margin: auto;
    gap: 12px;
`

const CloseIcon = styled(_CloseIcon)`
    align-self: flex-start;
    margin-top: 10px;
    position: absolute;
    inset: 0;
    display: block;
    ${desktop(css`
        display: none;
    `)}
`
const SegmentedControl = styled(SegmentedControl_)`
    width: 325px;
    display: flex;
    align-self: center;
`

const Logo = styled.img`
    margin: 0 auto;
    ${desktop(css`
        width: 320px;
        height: 150px;
    `)}
    width: 195px;
    height: 92px;
`

type Props = { messageId?: keyof typeof Messages }

const LoginPageContents = ({ messageId }: Props) => {
    const message = messageId && Messages[messageId]
    const params = useSearchParams()
    const router = useRouter()
    const isLogin = usePathname() === '/login'
    const { showMessage } = useContext(FlashMessageContext)
    useEffect(() => {
        if (message) {
            showMessage({ message: <AuthMessage {...message} />, type: message.type })
        }
    }, [message, showMessage])
    return (
        <>
            <Background />
            <OuterContainer>
                <Container>
                    <Link href="/">
                        <CloseIcon height={20} />
                    </Link>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <Logo src={`${giphyUrl}/static/img/be-animated.gif`} alt="Be Animated" />
                    <SegmentedControl
                        key={`segmented-control-${isLogin ? 'login' : 'join'}`}
                        defaultIndex={isLogin ? 0 : 1}
                        size="medium"
                        gradient={isLogin ? 'blue-green' : 'purple-indigo'}
                        options={['Log In', 'Sign Up']}
                        onChange={(index) => {
                            const p = params ? Object.fromEntries(params.entries()) : {}
                            const url = appendParams(new URL('/' + (index === 0 ? 'login' : 'join'), location.href), p)
                            router.push(url.toString())
                        }}
                    />
                    {isLogin ? <LoginForm next={params?.get('next')!} /> : <JoinForm />}
                </Container>
            </OuterContainer>
        </>
    )
}

export default LoginPageContents
