import { giphyDarkCharcoal, giphyDarkestGrey } from '@giphy/colors'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { getGifWidth } from '@giphy/js-util'
import { Gif } from '@giphy/react-components'
import { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import Button from 'ui/src/components/controls/button'
import { timeoutFetch } from 'utils/src/api/fetch'
import { NexaBlack } from '../../styles/fonts'
import { publicRuntimeConfig } from '@/app/util/env'
import { Form } from './sc'
import { getErrorMessages } from 'utils/src/api'
const peekerHeight = 120

const Container = styled.div`
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    position: absolute;
    flex-direction: column;
    inset: 0;
    justify-content: center;
    align-items: center;
    h2 {
        font-family: ${NexaBlack.style.fontFamily};
        font-weight: 300;
    }
    padding-bottom: ${peekerHeight}px;
`

const Peeker = styled.div<{ offset: number }>`
    height: ${peekerHeight}px;
    transform: translateY(${(props) => props.offset}px);
    position: relative;
`

const Inner = styled(Form)`
    min-width: 325px;
    max-width: 400px;
    background: ${giphyDarkestGrey};
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 10px;
    text-align: center;
    margin: 0 20px;
    p {
        font-size: 15px;
    }
`
type Props = {
    onClose: (email?: string) => void
}

const steps = [
    {
        title: 'Hello, again!',
        error: false,
        image: 'rYV2FpxZ3gUizs2usC',
        offset: 63,
    },
    {
        title: 'Oops!',
        error: true,
        image: '28DaHBc6rGZgaUSThG',
        offset: 10,
    },
] as const

const AuthModal = ({ onClose }: Props) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const emailInput = useRef<HTMLInputElement>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [stepNumber, setStepNumber] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const step = steps[stepNumber]
    const [gif, setGif] = useState<IGif>()
    useEffect(() => {
        const fetchGif = async () => {
            const { data: gif } = await new GiphyFetch(publicRuntimeConfig.apiKey!).gif(step.image)
            setGif(gif)
        }
        fetchGif()
    }, [step.image])
    const disabled = !emailInput.current?.checkValidity()
    const onSubmit = async () => {
        setIsLoading(true)
        const formData = new FormData()
        const email = emailInput.current!.value
        formData.append('email', email)
        const result = await timeoutFetch({
            url: '/api/v3/register/resend',
            options: {
                method: 'POST',
                body: formData,
            },
        })
        if (result.ok) {
            onClose(email)
        } else {
            const err = await result.json()
            setErrorMessage(getErrorMessages(err).join(''))
            setStepNumber(1)
        }
        setIsLoading(false)
    }
    return (
        <Container>
            <Peeker offset={step.offset}>
                {gif && <Gif gif={gif} width={getGifWidth(gif, peekerHeight)} backgroundColor="rgba(0,0,0,0)" noLink />}
            </Peeker>
            <Inner
                onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!disabled) onSubmit()
                }}
            >
                <h2>{step.title}</h2>
                <p>
                    {step.error
                        ? errorMessage
                        : "If you registered but did not confirm your account yet, enter your email address and we'll send you another confirmation email. Be sure to check your spam folder."}
                </p>
                <div>
                    <input
                        placeholder="Email Address"
                        name="email"
                        required
                        type="email"
                        ref={emailInput}
                        onInput={forceUpdate}
                    />
                    <div className="error-message">{emailInput.current?.validationMessage}</div>
                </div>
                <Button
                    disabled={disabled}
                    size="medium"
                    gradient="purple-indigo"
                    onClick={onSubmit}
                    loading={isLoading}
                >
                    Send
                </Button>
                <Button size="medium" color={giphyDarkCharcoal} onClick={() => onClose()}>
                    Close
                </Button>
            </Inner>
        </Container>
    )
}
export default AuthModal
