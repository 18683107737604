import { giphyAqua, giphyBlue, giphyDarkCharcoal, giphyGreen, giphyRed } from '@giphy/colors'
import { useCallback, useContext, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import styled, { css } from 'styled-components'
import { FlashMessageContext } from '../../context/flash-message'
import { NexaBlack } from '../../styles/fonts'
import Background from './background'
import { Button as _Button } from './sc'
import { desktop } from 'ui/src/css'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { timeoutFetch } from 'utils/src/api/fetch'

type EmailValidationProps = {
    email: string
}

const Title = styled.div`
    font-family: ${NexaBlack.style.fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    margin-bottom: 16px;
`

const Message = styled.div`
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
`

const Footer = styled.div`
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.11px;
`
const ResendLink = styled.div`
    font-weight: bold;
    cursor: pointer;
`
const Email = styled.span`
    color: ${giphyBlue};
    font-weight: bold;
`
const Error = styled.p`
    color: ${giphyRed};
    font-size: 15px;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    letter-spacing: 0.11px;
`
const Container = styled.div`
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0);
    display: flex;
    align-items: center;
    ${desktop(css`
        width: 50vw;
    `)}
`
const Inner = styled.div`
    width: 332px;
    flex-direction: column;
    display: flex;
    gap: 10px;
    margin: auto;
    align-items: center;
    text-align: center;
    position: relative;
`

const Button = styled(_Button)`
    width: 100%;
`
export const ReactCodeInputWrapper = styled.div<{ $error: any }>`
    margin-bottom: 20px;

    input {
        width: 52px;
        height: 52px;
        margin: 8px;
        border-radius: 4px;
        background: ${giphyDarkCharcoal};
        font-size: 48px;
        text-align: center;
        color: ${giphyAqua};
        font-size: 25px;
        font-family: ${NexaBlack.style.fontFamily};
        text-align: center;
        letter-spacing: 0.38px;
        line-height: 35px;
        border-width: 0;
        outline-width: 0;
        box-sizing: border-box;

        ${({ $error }) => {
            return $error
                ? `
      border-width: 2px;
      border-style: solid;
      border-color: ${giphyRed};
      color: ${giphyRed};
    `
                : ``
        }}

        &:focus {
            border-width: 2px;
            border-style: solid;
            border-color: ${giphyGreen};
        }
    }
`
const EmailValidation = ({ email }: EmailValidationProps) => {
    const [code, setCode] = useState('')
    const router = useRouter()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(false)

    const { showMessage } = useContext(FlashMessageContext)

    const codeChangedCallback = (value: string) => {
        setCode(value)
        setError(false)
    }

    const submitCode = useCallback(() => {
        setSubmitting(true)

        const submit = async () => {
            try {
                const response = await timeoutFetch({
                    url: `/api/v3/register/confirm`,
                    options: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email,
                            code,
                        }),
                    },
                })
                if (response.ok) {
                    showMessage({ message: 'Email Verified!', type: 'success' })
                    router.push('/login')
                } else {
                    const err = await response.json()
                    if (response.status === 410) {
                        showMessage({ message: err.details.code || 'error', type: 'error' })
                    } else {
                        setError(true)
                    }
                }
            } catch (err: any) {
                setError(true)
            } finally {
                setSubmitting(false)
            }
        }
        submit()
    }, [email, code, showMessage, router])

    return (
        <Container>
            <Background />
            <Inner>
                {/*  eslint-disable-next-line @next/next/no-img-element */}
                <img
                    src="https://media2.giphy.com/media/LOnt6uqjD9OexmQJRB/200w.webp"
                    width={100}
                    height={100}
                    alt=""
                />
                <Title>Check your Email!</Title>
                <Message>
                    We have sent an email to <Email>{email || 'test@email.com'}</Email>. Please click the link in the
                    email to verify your account or enter the email activation code below.
                </Message>
                <ReactCodeInputWrapper $error={error}>
                    <ReactCodeInput
                        name="input-code"
                        inputMode="verbatim"
                        fields={4}
                        type="text"
                        onChange={codeChangedCallback}
                        value={code}
                    />
                </ReactCodeInputWrapper>
                {error && <Error>Invalid Code</Error>}
                <Footer>
                    Didn&apos;t receive an email? Check your spam folder! <br />
                    Emails may take a couple minutes to arrive.
                </Footer>
                <Footer>Already validated your account?!</Footer>
                <Link href="/login">
                    <ResendLink>Go to Log In</ResendLink>
                </Link>
                <Button
                    size="medium"
                    gradient="purple-indigo"
                    loading={submitting}
                    disabled={code.length < 4}
                    onClick={submitCode}
                >
                    Submit
                </Button>
            </Inner>
        </Container>
    )
}

export default EmailValidation
