'use client'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { ComponentProps } from 'react'

type Props = { noLink?: boolean } & ComponentProps<typeof Link>
const SmartLink = ({ href, className, children, noLink, ...props }: Props) => {
    const router = useRouter()
    return noLink ? (
        <span
            className={className}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                router.push(href.toString())
            }}
        >
            {children}
        </span>
    ) : (
        <Link className={className} href={href} {...props}>
            {children}
        </Link>
    )
}
export default SmartLink
