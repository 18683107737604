import { SVGAttributes } from 'react'

const VerifiedIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7447 0L15.9028 2.55331L18.9947 3.81966L19.2419 7.15562L21 10L19.2419 12.8444L18.9947 16.1803L15.9028 17.4467L13.7447 20L10.5 19.2046L7.25532 20L5.09719 17.4467L2.00532 16.1803L1.75806 12.8444L0 10L1.75806 7.15562L2.00532 3.81966L5.09719 2.55331L7.25532 0L10.5 0.795382L13.7447 0ZM9.44644 15.2103L17.0434 7.52136L14.7399 5.19002L9.42133 10.5719L7.1186 8.24091L4.83951 10.5476L9.44644 15.2103Z"
        />
    </svg>
)

export default VerifiedIcon
