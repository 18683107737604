'use client'

import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { desktop } from 'ui/src/css'
import Attribution from '../attribution'
import { IUser } from '@giphy/js-types'
import { GET } from '@/app/util/url'
import { giphyLocalUrl } from '@/app/util/env'
import { timeoutFetch } from 'utils/src/api/fetch'

type AnimatedBackground = {
    id: number
    name: string
    artist: IUser
    mp4_url: string
    webm_url: string
    gif_url: string | null
}

const getAnimatedBackground = async () => {
    const f = await timeoutFetch({ url: `${giphyLocalUrl}/ajax/animated-backgrounds/`, options: GET() })
    return (await f.json()) as { result: AnimatedBackground[] }
}

const Container = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    opacity: 0.1;
    ${desktop(css`
        opacity: 1;
        width: 50vw;
        transform: translateX(100%);
    `)}
`

const AttributionContainer = styled.div`
    position: absolute;
    display: none;
    bottom: 20px;
    left: 20px;
    ${desktop(css`
        display: flex;
    `)}
`

const Background = () => {
    const [background, setBackground] = useState<AnimatedBackground>()

    useEffect(() => {
        const getBackground = async () => {
            let background
            try {
                const { result } = await getAnimatedBackground()
                background = result[Math.floor(Math.random() * result.length)]
                setBackground(background)
            } catch (e) {
                console.error(e)
            }
        }
        getBackground()
    }, [])

    return (
        background && (
            <Container>
                <video autoPlay loop muted playsInline>
                    <source src={background.mp4_url} type="video/mp4" />
                    <source src={background.webm_url} type="video/webm" />
                </video>
                <AttributionContainer>
                    <Attribution user={background.artist} />
                </AttributionContainer>
            </Container>
        )
    )
}
export default Background
