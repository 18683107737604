'use client'
import { cn } from '@/util/cn'
import { IUser } from '@giphy/js-types'
import { useRouter } from 'next/navigation'
import { useRef } from 'react'
import { IProfileUser } from 'types'
import { getAssetUrl } from '../util/asset-url'

type Props = { user: IUser; size?: number; className?: string }
const Avatar = ({ user, size = 80, className }: Props) => {
    const router = useRouter()
    const defaultAvatarId = useRef<number>(Math.floor(Math.random() * 5) + 1)
    const url = user.avatar_url || `https://media.giphy.com/avatars/default${defaultAvatarId.current}.gif`
    return (
        <img
            suppressHydrationWarning
            src={getAssetUrl(url, size)}
            width={size}
            height={size}
            alt={user.username}
            className={cn('object-cover', className)}
            onClick={() => {
                const url = (user as IProfileUser).profile_url || `/${user.username}`
                router.push(url)
            }}
        />
    )
}

export default Avatar
