import { giphyBlue, giphyRed, giphyWhite } from '@giphy/colors'
import styled from 'styled-components'
import _Button from 'ui/src/components/controls/button'

export const SocialButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`
export const PasswordVisibilityClassname = 'password-visibility'
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    .error-message {
        display: none;
        font-weight: bold;
        color: ${giphyRed};
    }

    input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: 2px solid black;
        padding: 10px;
    }

    /* To demonstrate that the validations only happen on blur, we apply this malpractice */
    input:focus {
        outline: none;
    }

    .${PasswordVisibilityClassname} {
        cursor: pointer;
        align-self: center;
        display: inline-flex;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    input:focus ~ .${PasswordVisibilityClassname} {
        display: none;
    }

    /* Only show invalid ring while not focused */
    input:not(:focus):not(:placeholder-shown):invalid {
        border-color: ${giphyRed};
    }

    input:not(:focus):not(:placeholder-shown):invalid ~ .error-message {
        display: block;
    }

    /* Only show valid ring while not focused and if a value is entered */
    /* :empty won't work here as that targets elements that have no childeren. Therefore we abuse :placeholder-shown */
    input:not(:focus):not(:placeholder-shown):valid {
        border-color: black;
    }
`
export const Button = styled(_Button)`
    flex-shrink: 0;
`
export const ForgotPassword = styled.a`
    color: ${giphyBlue};
    align-self: center;
    &:hover {
        color: ${giphyWhite};
    }
`

export const Terms = styled.div`
    color: ${giphyWhite};
    align-self: center;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    a {
        color: ${giphyBlue};
    }
    a:hover {
        color: ${giphyWhite};
    }
`

export const SocButton = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
`
